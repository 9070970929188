@import "core";
.modal-container.modal-mobile-menu {	
	.modal-contentwrap {
		color: $color-white;
		background: transparent linear-gradient(0deg, $color-primary 0%, lighten($color-primary, 10%) 100%) 0% 0% no-repeat padding-box;;
	}

	.modal-body {
		padding-top: calc(11rem + var(--safe-area-insets-top, env(safe-area-inset-top)));
		padding-bottom: calc(8.7rem + var(--safe-area-insets-bottom, env(safe-area-inset-bottom)));
	}
	
	.menu-title {
		display: block;
		font-size: 3rem;
		margin-bottom: 3.5rem;
	}
	
	.menu-list {
		list-style: none;
	}

	.menu-item {
		padding: 1.5rem 0;

		+ .menu-item {
			border-top: .1rem solid rgba($color-white, .2);
		}
	}

		.item-contentwrap {
			display: flex;
			align-items: center;
		}

		.item-icon {
			width: 3rem;
			height: 2rem;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
}